/**
 * @Describe axios基础构建
 * @date 2021-01-08
 */
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { Toast } from 'vant'
import userApi from '@/http/apis/user'
import {
  AxiosConfig,
  AxiosRequest,
  CustomConfigType,
  ServiceResponseType,
  CustomResponse,
} from '@/types/axiosType'
import store from '@/store'
import { inforConfig } from '@/config/inforConfig'
import ReqEncrypt from './reqEncrypt'
/**
 * 重新跳转用户用心登录
 * 100001:  无效的token
 * 100005: refresh token 异常
 * 541406: 用户不存在
 */
const loginAgain = [100001, 541406]
// 改变请求头路径
const urlHeader = ['/apiFAServer/serviceAuthentication/oauth/token']
// 不需要token白名单
const whiteUrl = ['/apiFAServer/serviceAuthentication/oauth/token']
// 创建axios实例
const AXIOS = axios.create({
  baseURL: '/',
  timeout: 1000 * 60,
  headers: { 'Content-Type': 'application/json' },
})
// 请求拦截器
AXIOS.interceptors.request.use(
  (config: AxiosConfig) => {
    const auth = store.state.auth
    // 修改请求头格式
    if (urlHeader.includes(config.url as string)) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    // 设置token
    if (auth && !whiteUrl.includes(config.url as string)) {
      config.headers.Authorization = 'Bearer ' + auth
    } else {
      delete config.headers.Authorization
    }
    if (inforConfig.IS_ENCRYPT) {
      config = ReqEncrypt.paramEncrypt(config)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

AXIOS.interceptors.response.use(
  async (response) => {
    const { data, status } = response
    // console.log(data, status, '接口响应')
    // 未登录
    if (status === 200 && data.rt_code === 0) {
      // 正常数据返回
      return Promise.resolve(data)
    } else if (loginAgain.includes(data.rt_code)) {
      /**
       * 重新跳转用户用心登录
       * 100001:  无效的token
       * 100005: refresh token 异常
       * 541406: 用户不存在
       */
      // 清除token和刷新token
      store.commit('removeToken')
      store.commit('removeRefreToken')
      // 重新跳转用户中心
      window.location.href = inforConfig.USER_DOMAIN_LOGIN
    } else if (data.rt_code === 100000) {
      /**
       * token过期刷新token
       * 100000:  token过期
       */
      try {
        // 重新请求刷新token接口
        const res = await userApi.loginApiRefreshLogin({
          // eslint-disable-next-line @typescript-eslint/camelcase
          grant_type: 'refresh_token',
          // eslint-disable-next-line @typescript-eslint/camelcase
          refresh_token: store.state.auth as string,
        })
        // 把token保存到vuex和本地
        await store.commit('getToken', res.data.access_token)
        // 把refretoken保存到vuex和本地
        await store.commit('getRefreToken', res.data.refresh_token)
        // 重新获取用户信息
        await store.dispatch('getUserData')
      } catch (error) {
        await store.commit('removeToken')
        await store.commit('removeRefreToken')
        console.log(error, '刷新token错误')
        // debugger
        // 重新跳转用户中心
        window.location.href = inforConfig.USER_DOMAIN_LOGIN
      }
      return Promise.resolve(response)
    } else {
      // 错误返回
      return Promise.reject(data || 'error')
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
axiosRetry(AXIOS, {
  retries: 2, // 重试发起次数
  shouldResetTimeout: true, // 自动重新发起请求
  retryDelay: (retryCount) => {
    return retryCount * 1000 // 每次重新发起请求的间隔时间
  },
  retryCondition: (error: { config: AxiosConfig }) => {
    error.config.retry = true // 重新发起请求的标识
    return error.config.method === 'get' || error.config.method === 'post'
  },
})
// 构建axios基础，响应拦截
export class BasicAxios {
  private requestCount = 0
  private errTip = false
  private defaultCustomConfig: CustomConfigType = {
    isNeedLoading: true,
    isNeedToken: false,
    isNeedShowError: true,
  }
  private handleLoading(customConfig: CustomConfigType, isOpen: boolean) {
    if (!customConfig.isNeedLoading) return
    // 不重复开启 Loading
    if (this.requestCount === 0 && !this.errTip) {
      Toast.clear()
      return
    }

    if (isOpen) {
      Toast.loading({ message: '加载中...', duration: 0 })
      return
    }
  }
  private apiAxios(config: AxiosRequest, customConfig: CustomConfigType): Promise<CustomResponse> {
    // 控制错误提示
    this.errTip = false
    // loading层控制
    customConfig.isNeedLoading && this.requestCount++
    this.handleLoading(customConfig, true)
    return new Promise((resolve, reject) => {
      AXIOS(config)
        .then((res: ServiceResponseType) => {
          resolve({
            status: true,
            code: res.rt_code !== undefined ? res.rt_code : -1,
            msg: res.rt_msg || '',
            data: res.data,
          })
        })
        .catch((err) => {
          // console.log(err, '接口错误')
          // debugger
          reject({
            status: false,
            code: err.rt_code,
            msg: err.rt_msg,
            data: err.data,
            err,
          })
          this.errTip = true
          Toast.fail({
            message: err.rt_msg || '接口错误',
            closeOnClickOverlay: true,
            overlay: true,
            closeOnClick: true,
          })
        })
        .finally(() => {
          customConfig.isNeedLoading && this.requestCount--
          this.handleLoading(customConfig, false)
        })
    })
  }

  /**
   * 网络请求
   */
  protected request(config: AxiosRequest, customConfig?: CustomConfigType) {
    customConfig = { ...this.defaultCustomConfig, ...customConfig }
    return this.apiAxios(config, customConfig)
  }

  // /**
  //  * POST类型的网络请求
  //  */
  // protected postReq({ url, data, params }: AxiosRequest) {
  //   return this.apiAxios({ url, method: 'POST', data, params })
  // }

  // /**
  //  * PUT类型的网络请求
  //  */
  // protected putReq({ url, data, params }: AxiosRequest) {
  //   return this.apiAxios({ url, method: 'PUT', data, params })
  // }

  // /**
  //  * DELETE类型的网络请求
  //  */
  // protected deleteReq({ url, data, params }: AxiosRequest) {
  //   return this.apiAxios({ url, method: 'DELETE', data, params })
  // }
}
