import { createApp } from 'vue'
import {
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Sticky,
  Search,
  List,
  Cell,
  CellGroup,
  Col,
  Row,
  Image as VanImage,
  Toast,
  Form,
  NavBar,
  Lazyload,
  Tag,
  Button,
  Icon,
  Field,
  Picker,
  Popup,
} from 'vant'
import 'lib-flexible/flexible'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/base.css'
// 将所有 Toast 的展示时长设置为 2秒
Toast.setDefaultOptions({ duration: 3000 })

const app = createApp(App)
app
  .use(Lazyload)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Tab)
  .use(Tabs)
  .use(Sticky)
  .use(Search)
  .use(List)
  .use(Cell)
  .use(CellGroup)
  .use(Col)
  .use(Row)
  .use(VanImage)
  .use(Toast)
  .use(Form)
  .use(NavBar)
  .use(Tag)
  .use(Button)
  .use(Icon)
  .use(Field)
  .use(Picker)
  .use(Popup)
  .use(store)
  .use(router)
  .mount('#app')
