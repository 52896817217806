
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import userApi from '@/http/apis/user'
import { inforConfig } from '@/config/inforConfig'
import { useRoute } from 'vue-router'
import cookies from 'js-cookie'
export default defineComponent({
  name: 'App',
  setup() {
    const store = useStore()
    const route = useRoute()
    onMounted(() => {
      // 自动登录
      if (localStorage.getItem('at_ln') && !cookies.get('token') && !route.query.code) {
        userApi
          .loginApiGetUserLogin({
            // eslint-disable-next-line @typescript-eslint/camelcase
            grant_type: 'automatic_login',
            code: localStorage.getItem('at_ln') as string,
          })
          .then(async (res) => {
            // 把token保存到vuex和本地
            await store.commit('getToken', res.data.access_token)
            await store.commit('getRefreToken', res.data.refresh_token)
            // 获取用户信息
            // store.dispatch('getUserData')
          })
          .catch(async (err) => {
            console.log(err, '自动登录错误')
            await store.commit('removeToken')
            await store.commit('removeRefreToken')
            // 清除自动登录标识
            localStorage.removeItem('at_ln')
            // debugger
            // 跳转用户中心登录
            window.location.href = inforConfig.USER_DOMAIN_LOGIN
          })
      }
      // 设置veux的token和刷新token
      if (cookies.get('token')) {
        store.commit('getToken', cookies.get('token'))
      }
      if (cookies.get('refretoken')) {
        store.commit('getRefreToken', cookies.get('refretoken'))
      }
    })
    return {
      store,
    }
  },
})
