import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import cookies from 'js-cookie'
// import store from '@/store'
import { isWeixinBrowser } from '@/plugins/isWeixinBrowser'
import { PLATFORM_CODE, USER_DOMAIN_INDEX, USER_SERVER_DOMAIN__NAME } from '@/config/inforConfig'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/loginJump',
    name: 'LoginJump',
    component: () => import('@/views/loginJump.vue'),
    // meta: { title: '用户中心跳转中间页' },
  },
  {
    path: '/',
    name: 'User',
    component: () => import('@/views/user/index.vue'),
    meta: { title: '怡合达-个人中心' },
  },
  {
    path: '/notice/:id',
    name: 'Notice',
    component: () => import('@/views/notice.vue'),
    meta: { title: '怡合达-消息' },
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { title: '怡合达-订单' },
  },
  {
    path: '/orderInfo/:type/:orderNo',
    name: 'OrderInfo',
    component: () => import('@/views/order/orderInfo.vue'),
    meta: { title: '怡合达-订单详情' },
  },
  {
    path: '/payResult/:orderNo',
    name: 'payResult',
    component: () => import('@/views/order/payResult.vue'),
    meta: { title: '支付成功' },
  },
  {
    path: '/orderlogistic',
    name: 'orderlogistic',
    component: () => import('@/views/order/orderlogistic.vue'),
    meta: { title: '怡合达-物流详情' },
  },
  {
    path: '/quote',
    name: 'Quote',
    component: () => import('@/views/quote/index.vue'),
    meta: { title: '怡合达-报价单' },
  },
  {
    path: '/onlineQuoteInfo/:billno',
    name: 'OnlineInfo',
    component: () => import('@/views/quote/onlineInfo.vue'),
    meta: { title: '怡合达-商城报价单详情' },
  },
  {
    path: '/offlineQuoteInfo/:billno',
    name: 'OfflineInfo',
    component: () => import('@/views/quote/offlineInfo.vue'),
    meta: { title: '怡合达-线下报价单详情' },
  },
  {
    path: '/qrcode',
    name: 'Qrcode',
    component: () => import('@/views/qrcode.vue'),
    meta: { title: '怡合达-客服微信二维码' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
// 路由拦截
router.beforeEach(async (to: any, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (isWeixinBrowser()) {
    if (to.query.openid) {
      cookies.set('YHD-fa_openid', to.query.openid)
    }
    if (!cookies.get('YHD-fa_openid')) {
      window.location.href =
        USER_SERVER_DOMAIN__NAME +
        `/buc/authentication/v1/0/official/official-openid?redirectUrl=${USER_DOMAIN_INDEX}&platformCode=${PLATFORM_CODE}`
    }
  }
  next()
})
export default router
